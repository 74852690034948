import React from 'react';
import { NamedLink } from '../../../../components';
import css from './InfoBoxes.module.css';

const InfoBoxes = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.box1}>
        <p className={css.box1Title}>
          Kirchentag - eine unabhängige Laienbewegung, ein einzigartiges Event und Gäste, die ein Bett suchen
        </p>
        <p className={css.box1Text}>
          Hi! 👋 Vom 30. April bis 4. Mai 2025 findet in Hannover der Kirchentag statt. Mit seinen zahlreichen kulturellen, geistlichen und gesellschaftlichen Veranstaltungen lockt er tausende Menschen aus Deutschland und der ganzen Welt in die Metropolregion. Um für die Teilnehmenden einen Schlafplatz zu vermitteln, wurde dieses Portal eingerichtet. Wir suchen Gastgeber:innen aus der Umgebung und bieten im Gegenzug dankbare Übernachtungsgäste.
        </p>
      </div>

      <div className={css.container}>
        <div className={css.box2}>
          <div className={css.box2Text}>
            💡 Du möchtest wissen, wie Unterkunft-Kirchentag funktioniert?
          </div>
          <NamedLink className={css.link} name="HowItWorksPage">
            Klicke hier
          </NamedLink>
        </div>
        <div className={css.box2}>
          <div className={css.box2Text}>
            🛡️ Wir nehmen das Thema sicheres Umfeld ernst und du?
          </div>
          <NamedLink className={css.link} name="SafetyCenterPage">
            Klicke hier
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

export default InfoBoxes;
